/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PortsInfo interface.
 */
export function instanceOfPortsInfo(value) {
    return true;
}
export function PortsInfoFromJSON(json) {
    return PortsInfoFromJSONTyped(json, false);
}
export function PortsInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'ssh': json['ssh'] == null ? undefined : json['ssh'],
        'http': json['http'] == null ? undefined : json['http'],
        'mysql': json['mysql'] == null ? undefined : json['mysql'],
    };
}
export function PortsInfoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'ssh': value['ssh'],
        'http': value['http'],
        'mysql': value['mysql'],
    };
}
